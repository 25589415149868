import { Row, Typography, Avatar, Button } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useSelector } from 'react-redux'
import tryGigstack from '../assets/gifs/tryGigstack.gif'

const TryGigstackCard = ({ hidePBG, type }) => {
    const posthog = usePostHog()
    const { brand } = useSelector((state) => state.data)
    if (hidePBG) return <></>

    return (
        <a href="https://calendly.com/gparedesg/demo-gigstack-embed" target="_blank" rel="noreferrer">
            <div
                style={{
                    borderRadius: '16px',
                    backgroundImage: `url(${tryGigstack})`,
                    backgroundSize: 'cover',
                    margin: 'auto',
                }}
            >
                <div
                    style={{
                        borderRadius: '16px',
                        border: '3px solid white',
                        width: '100%',
                        borderColor: 'white',
                        background: 'rgba(255, 255, 255, 0.35)',
                        backdropFilter: 'blur(14px)',
                        WebkitBackdropFilter: 'blur(14px)',
                        margin: '5px 0px',
                        overflow: 'hidden',
                        position: 'relative',
                        minHeight: '100px',
                        marginTop: '25px',
                        paddingBottom: '20px',
                        padding: '20px 10%',
                    }}
                    className="d-flex flex-column clickable"
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            border: '16px',
                            borderBlock: 'white',
                            height: '100%',
                            zIndex: 2,
                            opacity: 0.5,
                            // backdropFilter: 'blur(100px)',
                            // WebkitBackdropFilter: 'blur(100px)',
                        }}
                    ></div>
                    <div
                        className="glass"
                        style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            zIndex: 2,
                            border: 'none',
                        }}
                    ></div>
                    <div style={{ margin: 'auto', width: '80%' }}>
                        <Row justify="start" style={{ zIndex: 3 }}>
                            <Avatar.Group max={{count: 3, style:{ color: '#f56a00', backgroundColor: '#fde3cf' }}}>
                                <Avatar
                                    shape="circle"
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'var(--neutral-1)',
                                        border: '0px solid var(--neutral-1)',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <span className="russo-one">g</span>
                                </Avatar>
                                <Avatar
                                    shape="circle"
                                    src={brand?.logo}
                                    style={{
                                        // borderRadius: '10px',
                                        border: '0px solid var(--neutral-1)',
                                        backgroundColor: 'rgba(113, 113, 123, 0.2)',
                                    }}
                                />
                                <Avatar
                                    style={{
                                        backgroundColor: 'rgba(113, 113, 123, 0.2)',
                                        color: 'var(--neutral-1)',
                                        border: '0px',
                                    }}
                                    shape="circle"
                                >
                                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        +1K
                                    </Typography.Text>
                                </Avatar>
                            </Avatar.Group>
                        </Row>
                        {/* <Row justify="center" style={{ marginTop: '10px', zIndex: 3 }}>
                <Typography.Text style={{ textAlign: 'center', fontWeight: 'bold' }}>Únete a {brand?.alias} </Typography.Text>
            </Row> */}
                        <Row justify="start" style={{ marginTop: '10px', zIndex: 3 }}>
                            <Typography className="h6-regular neutral-1" style={{ textAlign: 'start' }}>
                                Tu también{' '}
                                <span className="h6-bold neutral-1">
                                    {type === 'pago' && 'automatiza tus pagos y facturas '}
                                    {type === 'autoFactura' && 'automatiza tu facturación '}
                                </span>
                                {type === 'autoFactura' && `como ${brand?.alias}`}
                            </Typography>
                        </Row>
                        {/* <Row justify="center" style={{ marginTop: '10px', zIndex: 3 }}>
                <Button
                    onClick={() => {
                        posthog.capture('click_try_gigstack', {
                            brand: brand?.alias,
                            on: window.location.pathname.split('/')[1]
                        })
                        window.open('https://calendly.com/gparedesg/30min?hide_gdpr_banner=1', '_blank')
                    }}
                >Agendar Demo</Button>
            </Row> */}
                    </div>
                </div>
            </div>
        </a>
    )

    // return (
    //     <div style={{ borderRadius: '10px', backgroundColor: '#EFECFE', width: '100%', }}>
    //         <div style={{ padding: '20px 30px 0px 20px', margin: '20px 0px' }} onClick={() => {
    //             window.open('https://gigstack.xyz/knowgigstack', '_blank')
    //         }}>
    //             <Typography.Title level={4} style={{ textAlign: 'left', marginTop: '0px' }}>Tú también automatiza la facturación</Typography.Title>
    //             <Row style={{ width: '100%', cursor: 'pointer' }} align="middle">
    //                 <div style={{ width: '40%' }}>
    //                     <Typography.Text style={{ textAlign: 'center', marginTop: '20px', color: '#8666FD' }}>Más información</Typography.Text>
    //                 </div>
    //                 <div className="long-arrow" style={{ width: '60%' }}></div>
    //             </Row>
    //         </div>
    //         <div style={{ height: '15px', marginTop: '10px', borderRadius: '0px 0px 10px 10px', backgroundImage: 'linear-gradient(to right, #F5E6FE 10%, #6F69F9 75%, #344FE2)' }}></div>
    //         {/* <div style={{ height: '8px', borderRadius: '0px 0px 10px 10px', backgroundImage: 'linear-gradient(to left, #F5E6FE 10%, #6F69F9 75%, #E8CEFE)' }}></div> */}
    //     </div>
    // )
}

export default TryGigstackCard
