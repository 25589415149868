import PaymentMethods from '../../components/PaymentMethods'
import { useParams } from 'react-router-dom'
import { Layout, Typography, ConfigProvider } from 'antd'
import { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingFullScreen from '../../components/LoadingFullScrren'
import { baseUrl } from '../../config/axios'
import CustomFloatButton from '../../components/FloatButton'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { setConfirmedMethod } from '../../context/payments/paymentsSlice'
import { useTranslation } from 'react-i18next'
// import Banner from '../../components/BannerGig';

const Pay = () => {
    const dispatch = useDispatch()
    const { id: token } = useParams()
    const { brand } = useSelector((state) => state.data)

    const { t, i18n } = useTranslation();

    const [paymentData, setpaymentData] = useState(null)
    const [loading, setloading] = useState(true)

    const RetrieveData = async () => {
        try {
            const paymentData = await axios
                .create({
                    baseURL: baseUrl,
                    headers: {
                        'cache-control': 'no-cache',
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                })
                .post('resolvePublicPayment', {})

            if (paymentData.status === 'canceled') {
                setpaymentData(null)
                // setcustomError(`El pago ha sido cancelado, por favor contacta a ${paymentData.user.firstName} para más información.`)
                return setloading(false)
            }

            dispatch(setData({ item: 'brand', data: paymentData?.data?.brand }))
            setpaymentData({
                ...paymentData?.data,
                handleIntentConfirmationChallenge:
                    paymentData?.data?.next_action?.type === 'intent_confirmation_challenge',
            })
            if(paymentData?.data.confirmedMethod){
                dispatch(setConfirmedMethod(paymentData?.data.confirmedMethod))
            }
            setloading(false)
        } catch (error) {
            // console.log(error);
            setpaymentData(null)
            setloading(false)
        }
    }

    let retrieveDataCalled = false

    useEffect(() => {
        if (token && !retrieveDataCalled) {
            RetrieveData()
            retrieveDataCalled = true
        }
    }, [token])

    useEffect(() => {
        if (brand?.isNonprofit) {
            i18n.changeLanguage('nonprofit')
        }
    }, [brand?.isNonprofit])

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: brand?.primaryColor ?? '#00b96b',
                    colorLink: brand?.primaryColor ?? '#00b96b',
                    colorText: '#333',
                    colorTextSecondary: '#333',
                },
                components: {},
            }}
        >
            <Layout style={{ padding: 0, margin: 0 }}>
                <Layout.Content
                    style={{
                        minHeight: '100vh',
                        width: '100%',
                        backgroundColor: '#F7F9FB',
                        padding: '10px 0px',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {loading ? (
                        <LoadingFullScreen loading={true} />
                    ) : paymentData ? (
                        <PaymentMethods payment={paymentData} token={token} />
                    ) : (
                        <div style={{ marginTop: '105px' }}>
                            <Typography.Text>
                                El {t("pago")} ha sido cancelado o eliminado, por favor ponte en contacto con la empresa que te
                                envío la solicitud.
                            </Typography.Text>
                        </div>
                    )}
                </Layout.Content>
                {/* <CustomFloatButton
                    type={paymentData?.brand?.supportPhone ? 'whatsapp' : 'mail'}
                    phone={paymentData?.brand?.supportPhone}
                    mail={paymentData?.brand?.supportEmail ?? 'ayuda@gigstack.io'}
                /> */}
            </Layout>
        </ConfigProvider>
    )
}

export default Pay
