import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import business from './versions/business.json';
import nonprofit from './versions/nonprofit.json';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

const resources = {
    business: {
        translation: business
    },
    nonprofit: {
        translation: nonprofit
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "business",
    interpolation: {
      escapeValue: false
    }
  });

  const withI18n = (WrappedComponent) => {
    const WithI18n = (props) => (
      <I18nextProvider i18n={i18n}>
        <WrappedComponent {...props} />
      </I18nextProvider>
    );
    WithI18n.displayName = `WithI18n(${getDisplayName(WrappedComponent)})`;
    return WithI18n;
  };

  function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

export default withI18n;
