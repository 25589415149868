import { Row, Typography, Space } from 'antd'
import {
    NumberCircleOne,
    NumberCircleTwo,
    NumberCircleThree,
} from '@phosphor-icons/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export const OxxoPaymentInfo = memo(({ paymentData }) => {
    const { t } = useTranslation()

    return (
        <Space
            direction="vertical"
            size="middle"
            style={{ width: '100%', textAlign: 'left', marginTop: '15px', marginBottom: '15px' }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleOne size={24} style={{ marginRight: '8px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Genera tu línea de captura dando click en <span className="p-base-bold"> &quot;Ver línea de captura&quot;.</span>
                </Typography.Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleTwo size={24} style={{ marginRight: '8px', minWidth: '24px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Generarás una línea de captura. <span className="p-base-bold">Tendrás hasta 72 hrs</span>{' '}
                    para realizar el {t("pago")} en tiendas OXXO.
                </Typography.Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleThree size={24} style={{ marginRight: '8px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Tu {t("pago")} se reflejará en tiempo real.
                </Typography.Text>
            </div>
        </Space>
    )
})

OxxoPaymentInfo.displayName = 'OxxoPaymentInfo'

const OxxoSection = memo((props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <OxxoPaymentInfo paymentData={props.paymentData} />
            <Row></Row>
        </div>
    )
})

OxxoSection.displayName = 'OxxoSection'

export default OxxoSection
