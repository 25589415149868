import { Button, Checkbox, Form, Input, message, Row, Space, Typography } from 'antd'
import OxxoSection from './stripe/OxxoSection'
import CardSection from './stripe/CardSection'
import loaderGif from '../../assets/gifs/loadingGif.gif'
import { memo, useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import PaymentCompleted from './PaymentCompleted'
import { confirmPaymentMethod, handleStripeCardErrorCodesToSpanish } from '../../functions/general'
import { useDispatch, useSelector } from 'react-redux'
import { setConfirmedMethod, setPaymentCompleted, setWaittingForOxxo } from '../../context/payments/paymentsSlice'
import CustomerBalanceSection from './stripe/CustomerBalanceSection'
import axios from 'axios'
import { baseUrl } from '../../config/axios'
import { useParams } from 'react-router-dom'
import { InstallmentsSection } from './stripe/InstallmentsSection'
import { CreditCard } from '@phosphor-icons/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CheckoutForm = memo((props) => {
    const [form] = Form.useForm()
    const stripe = useStripe()
    const elements = useElements()
    const { id: token } = useParams()

    const [loading, setLoading] = useState(false)
    const [installmentsLoading, setInstallmentsLoading] = useState(false)
    const [successPayment, setSuccessPayment] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState(null)
    const [error, setError] = useState(null)
    const [installments, setInstallments] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [checkbox, setCheckbox] = useState({ checked: false, disabled: true })
    const dispatch = useDispatch()
    const { waittingForOxxo, paymentCompletedContext } = useSelector((state) => state.payments)
    const [showOxxoFields, setShowOxxoFields] = useState(false)
    const { t } = useTranslation();

    CheckoutForm.propTypes = {
        paymentData: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        brand: PropTypes.object.isRequired,
        primaryColor: PropTypes.string.isRequired,
        oxxo: PropTypes.bool.isRequired,
    }

    const primaryColor = props.paymentData.user?.brand?.primaryColor || '#8666FF'

    const getInstallments = async () => {
        try {
            let paymentBody = {
                type: 'get_card_installments',
                stripe_payment_msi: {
                    paymentIntentId: props?.paymentData?.paymentIntent, // paymentIntent.id, || paymentData.payment_intent
                    paymentMethodId: paymentMethod.id,
                },
            }

            const installmentsResponse = await axios
                .create({
                    baseURL: baseUrl,
                    headers: {
                        'cache-control': 'no-cache',
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                })
                .post('resolvePublicPayment', paymentBody)

            const allInstallments = [{ count: 1 }].concat(installmentsResponse?.data?.available_plans || [])

            return allInstallments
        } catch (error) {
            throw new Error(error)
        }
    }

    const confirmInstallmentsPayment = async () => {
        try {
            let resultIntent
            if (!selectedPlan) {
                resultIntent = stripe.confirmCardPayment(props.paymentData.client_secret, {
                    payment_method: paymentMethod.id,
                })
                return resultIntent
            } else {
                const paymentBody = {
                    type: 'confirm_payment',
                    payment_intent_id: props?.paymentData?.paymentIntent,
                    selected_plan: selectedPlan?.count === 1 || !selectedPlan ? null : selectedPlan,
                }

                resultIntent = await axios
                    .create({
                        baseURL: baseUrl,
                        headers: {
                            'cache-control': 'no-cache',
                            'Content-Type': 'application/json',
                            accept: 'application/json',
                            authorization: `Bearer ${token}`,
                        },
                    })
                    .post('resolvePublicPayment', paymentBody)
                return resultIntent
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleCheckbox = async () => {
        try {
            if (!checkbox.checked) {
                setTimeout(() => {
                    setLoading(true)
                    setInstallmentsLoading(true)
                    setCheckbox({
                        ...checkbox,
                        checked: !checkbox.checked,
                        disabled: !checkbox,
                    })
                }, 100)

                const availableInstallments = await getInstallments()
                setInstallments(availableInstallments)
                setInstallmentsLoading(false)
                setLoading(false)
                setCheckbox({
                    checked: true,
                })
                setSelectedPlan(null)
            } else {
                setPaymentMethod(null)
                setInstallments([])
                setCheckbox({
                    checked: false,
                    disabled: true,
                })
                setSelectedPlan(null)
            }
        } catch (error) {
            message.error('Ocurrió un error al obtener los meses sin intereses')
            setInstallmentsLoading(false)
            setLoading(false)
            setCheckbox({
                checked: false,
                disabled: false,
            })
        }
    }

    const HandleCardPayment = async (event) => {
        try {
            const result = await confirmInstallmentsPayment()
            setPaymentResponse(result)
            if (result.error) {
                console.log(result.error)
                setError(result.error?.message)
                setSuccessPayment(false)
                setLoading(false)
                dispatch(setPaymentCompleted(false))
                setPaymentMethod(null)
                setCheckbox({
                    checked: false,
                    disabled: true,
                })
                setInstallments([])
            } else {
                setSuccessPayment(true)
                setLoading(false)
                dispatch(setPaymentCompleted(true))
                await confirmPaymentMethod({confirmedMethod: "card", token})
                dispatch(setConfirmedMethod("card"))
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            message.error(
                `Ocurrió un error al procesar el ${t("pago")}, no se ha realizado ningún cargo. Por favor intenta de nuevo.`,
            )
        }
    }

    const HandleOxxoPayment = async (event) => {
        const result = await stripe.confirmOxxoPayment(props.paymentData.client_secret, {
            payment_method: {
                billing_details: {
                    name: props.paymentData?.previewCustomer?.name || event?.name || 'Sin nombre',
                    email: props.paymentData?.previewCustomer?.email || event?.email || props?.paymentData?.brand?.supportEmail,
                },
            },
        })
        setPaymentResponse(result)
        if (result.error) {
            setError(result.error?.message)
            setPaymentResponse(result)
            setSuccessPayment(false)
            setLoading(false)
            setShowOxxoFields(true)
            props.succeededPayment(false)
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // console.log('SUCCEDED');
            }
            
            setSuccessPayment(true)
            dispatch(setWaittingForOxxo(true))
            setLoading(false)
            await confirmPaymentMethod({confirmedMethod: "oxxo", token})
            dispatch(setConfirmedMethod('oxxo'))
        }
    }

    const handleSubmit = async (event) => {
        try {
            if (!stripe || !elements) {
                return
            }

            if (!props.oxxo) {
                return await HandleCardPayment(event)
            } else {
                return await HandleOxxoPayment(event)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
            message.error(
                `Ocurrió un error al procesar el ${t("pago")}, no se ha realizado ningún cargo. Por favor intenta de nuevo.`,
            )
        }
    }

    const getButtonText = () => {
        if (installmentsLoading) {
            return 'Obteniendo meses sin intereses...'
        }

        return props.oxxo ? 'Ver línea de captura' : `Realizar ${t("pago")}`
    }

    // useEffect(() => {
    //     if (successPayment) {
    //         dispatch(setPaymentInReview(true))
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [successPayment])

    if (paymentCompletedContext && !props.oxxo) {
        return <PaymentCompleted />
    }
    if (waittingForOxxo && props.oxxo) {
        return (
            <div
                style={{ padding: '20px 20px', border: '1px solid #f7f7f7', borderRadius: '10px' }}
                className="d-flex flex-column"
            >
                <img
                    src="https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg"
                    alt="oxxo"
                    height={50}
                    width={50}
                />
                <Typography.Title level={5} style={{ margin: 0 }}>
                    En espera de {t("pago")}
                </Typography.Title>
                <Typography.Text>
                    Para obtener la línea de captura de nuevo por favor haz click en el siguiente botón
                </Typography.Text>
                <Button
                    type="dashed"
                    onClick={() => {
                        window.open(
                            paymentResponse?.paymentIntent?.next_action?.oxxo_display_details?.hosted_voucher_url,
                            '_blank',
                        )
                    }}
                    style={{ borderColor: props.paymentData?.brand?.primaryColor, marginTop: '25px' }}
                >
                    Ver línea de captura
                </Button>
            </div>
        )
    }

    const handleButtonClick = async () => {
        try {
            form.submit()
            setLoading(false)
        } catch (error) {
            message.error(
                `Ocurrió un error al procesar el ${t("pago")}, no se ha realizado ningún cargo. Por favor intenta de nuevo.`,
            )
            setLoading(false)
        }
    }

    const ToRender = () => {
        if (props.customerBalance) return <CustomerBalanceSection paymentData={props.paymentData} />

        const shouldShowNameField = !props.oxxo || !props?.paymentData?.previewCustomer?.name || showOxxoFields;
        const shouldShowEmailField = !props.oxxo || !props?.paymentData?.previewCustomer?.email || showOxxoFields;

        return (
            <>
                {props.oxxo && <OxxoSection paymentData={props.paymentData} />}
                {!paymentResponse && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Item
                            name="name"
                            label="Nombre completo"
                            style={{ marginBottom: '5px' }}
                            rules={[{ required: true, message: 'Por favor añade tu nombre completo' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Correo electrónico"
                            rules={[{ type: 'email', required: true, message: 'Por favor añade un correo' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Typography className='p-xsmall-regular'>Una vez generado el recibo, no podrás cambiar tu forma de {t("pago")}.</Typography>
                        {!props.oxxo && (
                            <>
                                <Typography.Text>
                                    <span style={{ color: 'red' }}>*</span>{' '}
                                    <span style={{ lineHeight: '24px' }} className="p-base-semi-bold neutral-1">
                                        Detalles de tu tarjeta
                                    </span>
                                </Typography.Text>
                                {!paymentMethod ? (
                                    <CardSection
                                        setCheckbox={setCheckbox}
                                        checkbox={checkbox}
                                        form={form}
                                        setPaymentMethod={setPaymentMethod}
                                    />
                                ) : (
                                    <div
                                        className={'rounded-md'}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            marginBottom: '5px',
                                            marginTop: '10px',
                                            padding: '3px 10px',
                                            border: '1px solid #d9d9d9',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Space direction="horizontal" align="center">
                                            <CreditCard
                                                style={{ margin: '0px' }}
                                                size={32}
                                                weight="fill"
                                                color="#d9d9d9"
                                            />
                                            <Typography
                                                className="p-base-regular neutral-3"
                                                style={{ marginLeft: '5px' }}
                                            >
                                                **** **** **** {paymentMethod.card.last4}
                                            </Typography>
                                        </Space>
                                        <Button 
                                            type="text"
                                            onClick={() => {
                                                setPaymentMethod(null);
                                                setCheckbox({
                                                    checked: false,
                                                    disabled: true,
                                                });
                                                setInstallments([]);
                                            }}
                                            style={{ 
                                                color: primaryColor,
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            Editar
                                        </Button>
                                    </div>
                                )}
                                <Checkbox
                                    disabled={checkbox.disabled}
                                    checked={checkbox.checked}
                                    onChange={handleCheckbox}
                                    style={{ marginTop: '10px' }}
                                >
                                    Consultar meses sin intereses
                                </Checkbox>
                                {installments?.length > 0 && (
                                    <InstallmentsSection
                                        installments={installments}
                                        setSelectedPlan={setSelectedPlan}
                                        setLoading={setLoading}
                                        selectedPlan={selectedPlan}
                                        amount={props.paymentData.itemsAmounts.total}
                                        primaryColor={primaryColor}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}

                {props.oxxo && (
                    <Typography className='p-xsmall-regular'>
                        Una vez generada la línea de captura, no podrás cambiar tu forma de pago.
                    </Typography>
                )}

                {loading && paymentResponse ? (
                    <img src={loaderGif} alt="Cargando..." width={50} height={50} />
                ) : (
                    stripe &&
                    elements &&
                    !paymentResponse && (
                        <Row justify="center" style={{ marginTop: '50px' }}>
                            <Button
                                className="btn-shadow"
                                size="large"
                                style={{
                                    marginTop: '10px',
                                    borderColor: primaryColor,
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    width: '100%',
                                }}
                                htmlType="button"
                                onClick={() => {
                                    handleButtonClick()
                                }}
                                loading={loading}
                            >
                                {getButtonText()}
                            </Button>
                        </Row>
                    )
                )}
                {!paymentResponse && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography.Text
                            type="secondary"
                            style={{ fontSize: '10px', textAlign: 'center', width: '100%' }}
                        >
                            Al continuar aceptas los{' '}
                            <span
                                className="clickable "
                                style={{ color: primaryColor }}
                                onClick={() =>
                                    window.open(
                                        'https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf',
                                        '_blank',
                                        'noopener',
                                    )
                                }
                            >
                                términos y condiciones
                            </span>
                        </Typography.Text>
                    </div>
                )}

                {paymentResponse && !successPayment && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid red',
                            borderRadius: '10px',
                            padding: '10px',
                            marginTop: '25px'
                        }}
                    >
                        <Typography.Text
                            style={{ fontSize: '14px', marginBottom: '0px', fontWeight: 'bold', textAlign: 'center' }}
                        >
                            {error ||
                                handleStripeCardErrorCodesToSpanish(paymentResponse?.error?.decline_code) ||
                                paymentResponse?.error?.message}
                        </Typography.Text>

                        <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} type="secondary">
                            No hemos realizado ningún cargo.
                        </Typography.Text>
                        <Button
                            type="ghost"
                            className="btn-shadow"
                            htmlType="button"
                            style={{
                                marginTop: '15px',
                                border: `1px solid ${primaryColor ?? '#333'}`,
                                color: primaryColor ?? '#333',
                            }}
                            onClick={() => setPaymentResponse(null)}
                        >
                            Probar de nuevo
                        </Button>
                    </div>
                )}

                {!props.oxxo && !paymentResponse && (
                    <Row justify="center" style={{ marginTop: '15px', width: '100%' }}>
                        <Space>
                            <img
                                src="https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/mastercard.svg"
                                alt="mastercard"
                                width={20}
                                height={20}
                                style={{ height: '35px' }}
                            />
                            <img
                                src="https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/visa.svg"
                                alt="visa"
                                width={20}
                                height={20}
                                style={{ height: '35px' }}
                            />
                            <img
                                src="https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/amex.svg"
                                alt="amex"
                                width={20}
                                height={20}
                                style={{ height: '35px' }}
                            />
                        </Space>
                    </Row>
                )}

                <Row justify="center" style={{ marginTop: '50px' }}>
                    <Typography.Link
                        href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                        target="_blank"
                        className="neutral-3-anchor p-xsmall-regular"
                    >
                        aviso de privacidad
                    </Typography.Link>
                </Row>
            </>
        )
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ width: '100%' }}
            initialValues={{
                ...(props.paymentData?.previewCustomer ?? {}),
            }}
        >
            <ToRender />
        </Form>
    )
})

CheckoutForm.displayName = 'CheckoutForm'

export default CheckoutForm
